import {
  useFetchApi,
  buildUrl,
  usePostApi,
  useDeleteApi,
  usePatchApi,
  useUpdateApi,
  type UseMutationApiCallbacks,
  type WithParamsFun,
  useParameterizedFetchApi,
  $fetch,
} from '@slc/fetch'
import { baseUrl, getSigninLinkHref } from './links'
import { insertParamsIntoUrl, UrlParams, UrlQuery } from '@slc/utils'

export const apiUrl = (
  path: string,
  params?: UrlParams | null | undefined,
  query?: UrlQuery | null | undefined
) => buildUrl(baseUrl as string, path, params, query)

export type FetchDashboardApiOptions = RequestInit & {
  path: string
  params?: UrlParams | null | undefined
  query?: UrlQuery | null | undefined
  locale?: string
}

export function fetchDashboardApi({
  method = 'GET',
  path,
  params,
  query,
  locale,
  ...rest
}: FetchDashboardApiOptions) {
  if (!locale) {
    throw new Error('Missing locale')
  }

  return $fetch(dashboardOptions.baseApiUrl(path, params, query), {
    method,
    ...rest,
    headers: {
      ...rest.headers,
      ['x-country']: locale,
    },
  })
}

export const dashboardOptions = {
  baseApiUrl: (
    path: string,
    params?: UrlParams | null | undefined,
    query?: UrlQuery | null | undefined
  ) => apiUrl(path, params, query),
  signinUrl: getSigninLinkHref,
}

export const useDashboardApi = (
  path: string,
  params?: UrlParams | null | undefined,
  query?: UrlQuery | null | undefined
) => useFetchApi(path, params, query, dashboardOptions)

export const useGetDashboardApi = <T>(path: string, withParams: WithParamsFun) =>
  useParameterizedFetchApi<T>(path, withParams, dashboardOptions)

export const usePostDashboardApi = (
  path: string,
  params?: UrlParams | null | undefined,
  body?: BodyInit | Record<string, any> | null | undefined,
  swrMutationOptions?: UseMutationApiCallbacks
) =>
  usePostApi(
    {
      path,
      params,
      body,
      options: dashboardOptions,
    },
    swrMutationOptions
  )

export const useUpdateDashboardApi = (
  path: string,
  params?: UrlParams | null | undefined,
  body?: BodyInit | Record<string, any> | null | undefined,
  swrMutationOptions?: UseMutationApiCallbacks
) =>
  useUpdateApi(
    {
      path,
      params,
      body,
      options: dashboardOptions,
    },
    swrMutationOptions
  )

export const usePatchDashboardApi = (
  path: string,
  params?: UrlParams | null | undefined,
  body?: BodyInit | Record<string, any> | null | undefined,
  swrMutationOptions?: UseMutationApiCallbacks
) =>
  usePatchApi(
    {
      path,
      params,
      body,
      options: dashboardOptions,
    },
    swrMutationOptions
  )

export const useDeleteDashboardApi = (
  path: string,
  params?: UrlParams | null | undefined,
  swrMutationOptions?: UseMutationApiCallbacks
) =>
  useDeleteApi(
    {
      path,
      params,
      options: dashboardOptions,
    },
    swrMutationOptions
  )

export type FetchApiOptions = {
  country?: string | undefined
}

export type FetchApiProps = {
  path: string
  params?: UrlParams | null | undefined
  query?: UrlQuery | null | undefined
  options?: FetchApiOptions
}

export const fetchApi = <T = unknown>({ path, params, query, options }: FetchApiProps) => {
  const { baseApiUrl } = dashboardOptions

  const country = options?.country ?? ''

  if (!country) {
    console.error('Missing country selection')
  }
  return $fetch<T>(baseApiUrl(insertParamsIntoUrl(path, params, query)), {
    headers: {
      ['x-country']: country,
    },
  })
}

export const API_PATHS = {
  order: {
    orderStats: '/order/stats',
    orderList: '/order/list',
    orderSummary: '/order/:oid/summary',
    orderSummaryForProduction: '/order/:oid/productionSummary',
    cancelOrder: '/order/:oid/cancel',

    triggerOverdue: '/order/:oid/overdue',
    markAsPaid: '/order/:oid/paid',
    markAsUnpaid: '/order/:oid/unpaid',
    changePaymentType: '/order/:oid/changePaymentType',
    setManual: '/order/:oid/manual',
    informationExpected: '/order/:oid/information/expected',
    informationReceived: '/order/:oid/information/received',
    sendInformationMessage: '/order/:oid/information/message/send',
    informationMessageReceived: '/order/:oid/information/message/received',
    sendCustomerMessage: '/order/:oid/:cid/message',
    getInvoiceDocument: '/order/:oid/document/invoice',
    getRefundDocument: '/order/:oid/document/refund',
    getStatementDocument: '/order/:oid/document/statement/:ref',
    addCreditOrder: '/order/create/credit',
  },
  orderMessage: {
    orderMessageIncoming: '/order/message/incoming',
    orderMessageWaiting: '/order/message/waiting',
    orderMessageProduction: '/order/message/production',
    orderMessageDelivered: '/order/message/delivered',
    orderMessageCancelled: '/order/message/cancelled',

    updateSelection: '/order/message/:omid/selection',
    changeVoice: '/order/message/:omid/voice',
  },
  invoice: {
    invoiceList: '/invoice/list',
    refundInvoice: '/invoice/:oiid/refund',
    changePaymentType: '/invoice/:oiid/changePaymentType',
    markAsPaid: '/invoice/:oiid/paid',
    markAsUnpaid: '/invoice/:oiid/unpaid',
    triggerOverdue: '/invoice/:oiid/overdue',
  },
  refund: {
    refundList: '/refund/list',
  },
  quote: {
    quoteList: '/quote/list',
  },
  producer: {
    list: '/producer/list',
    voice: {
      list: '/producer/voice/list',
      listOptions: '/producer/voice/options',
      listOptionsByLang: '/producer/voice/options/:lid',
      available: '/producer/voice/available',
      availableForLang: '/producer/voice/available/:lid',
      messages: '/producer/voice/:pdid/messages',
      notificationsPending: '/producer/voice/notifications/pending',
      notificationsConfirm: '/producer/voice/notifications/confirm',
    },
    translation: {
      list: '/producer/translation/list',
      listOptions: '/producer/translation/options',
      listOptionsByLang: '/producer/translation/options/:lid',
      available: '/producer/translation/available',
      availableForLang: '/producer/translation/available/:lid',
      messages: '/producer/translation/:pdid/messages',
      notificationsPending: '/producer/translation/notifications/pending',
      notificationsConfirm: '/producer/translation/notifications/confirm',
    },
    availability: '/producer/:pdid/availability',
  },
  production: {
    prepare: '/order/:oid/production/prepare',

    waiting: '/production/waiting',
    running: '/production/running',
    delivered: '/production/delivered',
    cancelled: '/production/cancelled',
    setManual: '/production/:prid/manual',
    sendInformations: '/production/:prid/informations/send',
    receivedInformations: '/production/:prid/informations/received',
    changeVoiceProducer: '/production/:prid/producer/voice',
    changeTranslatorProducer: '/production/:prid/producer/translator',
    launch: '/production/:prid/launch',
    relaunch: '/production/:prid/relaunch',
    complete: '/production/:prid/complete',

    message: {
      voice: '/production/message/voice',
      translation: '/production/message/translation',
    },
  },
  productionMessage: {
    updateBlockStatus: '/production/message/:prmid/:bid/status',
    updateBlockText: '/production/message/:prmid/:bid/text',
    addBlockInstructions: '/production/message/:prmid/:bid/instructions',
    updateBlockTranslation: '/production/message/:prmid/:bid/translation',
    uploadBlockFile: '/production/message/:prmid/:bid/file',
    validateBlockTranslation: '/production/message/:prmid/:bid/translation/validate',
    updateFirm: '/production/message/:prmid/firm',
  },
  customer: {
    customerList: '/customer/list',
    customer: '/customer/:cid',
    contacts: '/customer/:cid/contacts',
    search: '/customer/search/:name',
    changeEmail: '/customer/:cid/change-email',
    changePassword: '/customer/:cid/change-password',
    changeType: '/customer/:cid/change-type',
    changeContacts: '/customer/:cid/update-contacts',
    update: '/customer/:cid/update',
    balance: '/customer/:cid/balance',
    create: '/customer/create',
    exists: '/customer/exists',
  },
  mail: {
    mailList: '/mail/list',
    mail: '/mail/:mid',
    mailHtml: '/mail/:mid/html',
  },
  message: {
    messageList: '/message/list',
  },
  music: {
    orderInfos: '/music/orderInfos/:oid',
    musicInfos: '/music/:cid/:mid/infos',
  },
  promoCode: {
    promoCodeList: '/promocode/list',
    promoCodeGet: '/promocode/:pcid',
    updatePromoCode: '/promocode/:pcid',
    deletePromoCode: '/promocode/:pcid',
    createPromoCode: '/promocode',
    search: '/promocode/search/:name',
    availability: '/promocode/search/availability',
  },
  pricing: {
    pricing: '/pricing/:type',
  },
  credit: {
    creditList: '/credit/list',
    transactionList: '/credit/transactions',
    creditGet: '/credit/:cid',
    addTransaction: '/credit/:cid/transaction',
  },
  statement: {
    statementList: '/statement/list',
  },
  voice: {
    voiceList: '/voice',
    unavailableList: '/voice/unavailable',
    availableList: '/voice/available',
  },
  site: {
    siteInfos: '/site/infos',
  },
  user: {
    user: '/user/:uid',
    userList: '/user',
    userInfos: '/user/infos',
  },
  file: {
    audioUrl: '/file/audio',
    invoiceUrl: '/file/document/invoice',
    allInvoiceUrl: '/file/document/invoice/all',
    refundUrl: '/file/document/refund',
    statementUrl: '/file/document/statement',
    uploadAudioUrl: '/file/upload/audio',
  },
  data: {
    message: {
      semiStandard: '/data/message/semiStandard/:type',
    },
  },
}
