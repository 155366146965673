import { getTime, formatDistanceToNow } from 'date-fns'
import isNil from 'lodash/isNil'
import { dayjs } from './date'

const formatDateSafely = (date: string | Date, format: string): string => {
  if (isNil(date)) {
    return ''
  }

  const $date = dayjs(date)

  if (!$date.isValid()) {
    return ''
  }

  return $date.format(format)
}

export const formatShortDate = (date: string | Date): string =>
  formatDateSafely(date, 'dd/MM/yy')

export const formatDate = (date: string | Date): string =>
  formatDateSafely(date, 'DD/MM/YYYY')

export const formatFullDate = (date: string | Date): string =>
  formatDateSafely(date, 'DD MMMM YYYY')

export const formatDateTime = (date: string | Date): string =>
  formatDateSafely(date, 'dd MMM yyyy p')

export const formatDateTimeSuffix = (date: string | Date): string =>
  formatDateSafely(date, 'dd/MM/yyyy hh:mm p')

export const formatTimestamp = (date: string | Date | number): number =>
  getTime(new Date(date))

export const formatToNow = (date: string | Date | number): string =>
  formatDistanceToNow(new Date(date), {
    addSuffix: true,
  })

export const formatISODate = (date: string | Date): string =>
  formatDateSafely(date, 'YYYY-MM-DD')

export const formatISODateTime = (date: string | Date): string =>
  formatDateSafely(date, 'YYYY-MM-DDTHH:mm:ss.Z')

export const formatISODateUTC = (date: string | Date): string =>
  formatDateSafely(date, 'YYYY-MM-DDT00:00:00+00:00')
