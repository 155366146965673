import { UserRole } from '@/types/user'
import { UserProfile } from '@auth0/nextjs-auth0'
import auth0, { ROLE_CLAIM } from '@slc/auth0'
import {
  ACCOUNTING_ROLE,
  ADMIN_ROLE,
  CUSTOMER_ROLE,
  MANAGER_ROLE,
  PREMIUM_ROLE,
  PRODUCER_ROLE,
  RESELLER_ROLE,
  TRANSLATOR_ROLE,
} from '@slc/constants/role'
import { ReqSessionUser } from '@slc/types'
import intersection from 'lodash/intersection'

import getConfig from 'next/config'

export default auth0(getConfig().serverRuntimeConfig.AUTH0_BASE_URL)

// export const ROLE_CLAIM: string = 'https://studio-lowcost.com/roles'
// export const UID_CLAIM: string = 'https://studio-lowcost.com/uid'

export const getUserRoles = (user: UserProfile | undefined): UserRole[] => {
  // console.log('getUserRoles')
  if (!user) {
    return []
  }

  return sortRolesByLevel(filterAllowedLevels(user[ROLE_CLAIM] as UserRole[]))
}

export const hasRole = (user: UserProfile | undefined, role: UserRole): boolean => {
  // console.log('hasRole')
  if (!user) {
    return false
  }

  const roles = getUserRoles(user)

  return (roles || []).includes(role)
}

export const isUserAllowed = (user: UserProfile | undefined, roles?: UserRole[]): boolean => {
  // console.log('isUserAllowed')
  if (!user) {
    return false
  }

  const currentRoles = getUserRoles(user)

  if (typeof roles === 'undefined') {
    return currentRoles.length > 0
  }

  return intersection(currentRoles, roles).length > 0
}

export const isSessionAllowed = (session: ReqSessionUser | null) => {
  console.log('isSessionAllowed', session)
  if (!session) {
    return false
  }

  const roles = sortRolesByLevel(filterAllowedLevels(session.roles as UserRole[]))
  console.log(roles)

  if (!roles.length) {
    return false
  }

  const higherRole: number = roles.reduce(
    (acc: number, role: UserRole) =>
      roleLevels[role] > roleLevels[CUSTOMER_ROLE] ? roleLevels[role] : acc,
    roleLevels[CUSTOMER_ROLE]
  )

  return higherRole > roleLevels[CUSTOMER_ROLE]
}

const roleLevels = {
  [ADMIN_ROLE]: 10,
  [MANAGER_ROLE]: 7,
  [ACCOUNTING_ROLE]: 7,
  [PRODUCER_ROLE]: 3,
  [TRANSLATOR_ROLE]: 3,
  // forbidden access
  [RESELLER_ROLE]: 0,
  [PREMIUM_ROLE]: 0,
  [CUSTOMER_ROLE]: 0,
}

const filterAllowedLevels = (roles: UserRole[]): UserRole[] =>
  roles.filter((role) => roleLevels[role] > 0)

const sortRolesByLevel = (roles: UserRole[]): UserRole[] =>
  roles.sort((a, b) => {
    const levelA = roleLevels[a]
    const levelB = roleLevels[b]

    if (levelA === levelB) {
      return 0
    }

    return levelA > levelB ? 1 : -1
  })
