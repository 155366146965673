// import type { SessionCache } from '@auth0/nextjs-auth0';
import { initAuth0 } from '@auth0/nextjs-auth0'

export default function initAuth0Sdk(baseURL: string) {
  return initAuth0({
    secret: process.env.AUTH0_SECRET,
    issuerBaseURL: `https://${process.env.AUTH0_DOMAIN}`,
    baseURL: baseURL || process.env.AUTH0_BASE_URL,
    clientSecret: process.env.AUTH0_CLIENT_SECRET,
    clientID: process.env.AUTH0_CLIENT_ID,
    auth0Logout: true,
  })
}

export const ROLE_CLAIM = 'https://studio-lowcost.com/roles'
export const UID_CLAIM = 'https://studio-lowcost.com/uid'
