export type ErrorResponse = {
  status: number
  body: unknown
}

export class ApiError extends Error {
  public status: number
  public body: unknown

  constructor(message: string, { status, body }: ErrorResponse) {
    super(message)

    this.name = 'ApiError'
    this.status = status
    this.body = body
  }
}

export class FechError extends Error {
  constructor(message: string) {
    super(message)

    this.name = 'FechError'
  }
}
