// i18n
import '../locales/i18n'

// highlight
import '@/utils/highlight'

// scroll bar
import 'simplebar/src/simplebar.css'

// lightbox
import 'react-image-lightbox/style.css'

// editor
import 'react-quill/dist/quill.snow.css'

// slick-carousel
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'

// fullcalendar
import '@fullcalendar/common/main.min.css'
import '@fullcalendar/daygrid/main.min.css'

import cookie from 'cookie'
import { ReactElement, ReactNode, useEffect, useState } from 'react'
// next
import { NextPage } from 'next'
import Head from 'next/head'
import App, { AppProps, AppContext } from 'next/app'
//
import { Provider as ReduxProvider } from 'react-redux'
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { type Locale } from 'date-fns'
import frDateFnsLocale from 'date-fns/locale/fr'

// redux
import { store } from '../redux/store'
// utils
import { getSettings } from '@/utils/getSettings'
// contexts
import { SettingsProvider } from '../contexts/SettingsContext'
import { CollapseDrawerProvider } from '../contexts/CollapseDrawerContext'
// theme
import ThemeProvider from '../theme'
// components
import ThemeSettings from '@/components/settings'
import { SettingsValueProps } from '@/components/settings/type'
import { ChartStyle } from '@/components/chart'
import ProgressBar from '@/components/ProgressBar'
import NotistackProvider from '@/components/NotistackProvider'
import MotionLazyContainer from '@/components/animate/MotionLazyContainer'
import { ConfirmProvider } from 'material-ui-confirm'

// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version

import { UserProvider as AuthProvider } from '@auth0/nextjs-auth0'
import { CountryProvider } from '@/contexts/CountryContext'
import SessionProvider from '@/contexts/SessionContext'

import { Analytics } from '@vercel/analytics/react'
import { ApiCallProvider } from '@/contexts/ApiCall'

import { defaultLocale } from 'config'
import { useCurrentRouterLocale } from '@slc/hooks'

const getLocaleDateFnsAdapter = async (locale: string): Promise<Locale> => {
  const localeAdapter = await import(
    /* webpackMode: "lazy", webpackChunkName: "df-[index]", webpackExclude: /_lib/ */
    `date-fns/locale/${locale}/index.js`
  )

  return localeAdapter
}

// ----------------------------------------------------------------------

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
  authRequired: boolean
}

interface MyAppProps extends AppProps {
  settings: SettingsValueProps
  Component: NextPageWithLayout
}

export default function MyApp(props: MyAppProps) {
  const { Component: BaseComponent, pageProps, settings } = props
  const getLayout = BaseComponent.getLayout ?? ((page) => page)

  const [locale, setLocale] = useState<string>(defaultLocale)
  const [localeAdapter, setLocaleAdapter] = useState<Locale>(frDateFnsLocale)
  const routerLocale = useCurrentRouterLocale()
  console.log('App -> locale', locale)

  useEffect(() => {
    const importLocaleAdapter = async (locale: string) => {
      const adapter = await getLocaleDateFnsAdapter(locale)
      setLocaleAdapter(adapter)
    }

    if (routerLocale !== locale) {
      setLocale(routerLocale)
      importLocaleAdapter(routerLocale)
    }
  }, [routerLocale, locale])

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>

      <AuthProvider>
        <SessionProvider router={props.router} authRequired={BaseComponent.authRequired}>
          <ReduxProvider store={store}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeAdapter}>
              <CollapseDrawerProvider>
                <SettingsProvider defaultSettings={settings}>
                  <CountryProvider locale={locale}>
                    <ApiCallProvider>
                      <MotionLazyContainer>
                        <ThemeProvider>
                          <ThemeSettings>
                            <ConfirmProvider
                              defaultOptions={{
                                confirmationButtonProps: { autoFocus: true },
                              }}
                            >
                              <NotistackProvider>
                                <ChartStyle />
                                <ProgressBar />
                                {/* {getLayout(<Component {...pageProps} />)} */}
                                {getLayout(<BaseComponent {...pageProps} />)}
                              </NotistackProvider>
                            </ConfirmProvider>
                          </ThemeSettings>
                        </ThemeProvider>
                      </MotionLazyContainer>
                    </ApiCallProvider>
                  </CountryProvider>
                </SettingsProvider>
              </CollapseDrawerProvider>
            </LocalizationProvider>
          </ReduxProvider>
        </SessionProvider>
      </AuthProvider>

      <Analytics />
    </>
  )
}

// ----------------------------------------------------------------------

MyApp.getInitialProps = async (context: AppContext) => {
  const appProps = await App.getInitialProps(context)

  const cookies = cookie.parse(
    context.ctx.req ? context.ctx.req.headers.cookie || '' : document.cookie
  )

  const settings = getSettings(cookies)

  return {
    ...appProps,
    settings,
  }
}
