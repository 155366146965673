import { getCurrentRouterLocale } from '@slc/hooks'
import type { ApiHooksResult } from '@slc/types'
import type { UrlParams, UrlQuery } from '@slc/utils'
import { insertParamsIntoUrl } from '@slc/utils'
import isPlainObject from 'lodash/isPlainObject'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import type { FetcherResponse } from 'swr/_internal'
import type { SWRMutationConfiguration } from 'swr/mutation'
import useSWRMutation from 'swr/mutation'

import { ApiError } from '../error'
import { $get, type FetchBody } from '../fetch'

const fetcher = (url: string, options: RequestInit) => $get(url, options)

type BaseApiUrl = (
  path: string,
  params?: UrlParams | null | undefined,
  query?: UrlQuery | null | undefined
) => string

export type FetchApiOptions = {
  baseApiUrl: BaseApiUrl
  signinUrl: string
  country?: string
}

const DEFAULT_METHOD = 'get'

const getCountryHeader = ({
  country,
  router,
}: {
  country?: string
  router?: NextRouter
}) => {
  const countryHeaderValue = country || getCurrentRouterLocale(router)

  if (!countryHeaderValue) {
    throw new Error('Missing country selection')
  }

  return countryHeaderValue
}

type FetchOptionsProps = {
  method: string
  body: FetchBody
  options: FetchApiOptions
  isJson: boolean
  country?: string
  router?: NextRouter
}
const fetchOptions = ({
  method = DEFAULT_METHOD,
  body,
  options,
  router,
  isJson = false,
}: FetchOptionsProps) => {
  const { country, ...others } = options

  return {
    method,
    ...others,
    headers: {
      ['x-country']: getCountryHeader({ country, router }),
      ...(method === DEFAULT_METHOD && isJson
        ? {}
        : {
            ['content-type']: 'application/json',
          }),
    },
    ...(body ? { body } : {}),
  }
}

const useBoundFetcher = (options: FetchApiOptions, router: NextRouter) => {
  const { baseApiUrl, country, ...otherOptions } = options

  return (urlPath) =>
    fetcher(baseApiUrl(urlPath), {
      ...otherOptions,
      headers: {
        ['x-country']: getCountryHeader({ country, router }),
      },
    }) as FetcherResponse<Data>
}

export const useFetchApi = <Data = unknown>(
  path: string,
  params: UrlParams | null | undefined,
  query: UrlQuery | null | undefined,
  options: FetchApiOptions
): ApiHooksResult => {
  const { signinUrl } = options

  const router = useRouter()

  const boundFetcher = useBoundFetcher(options, router)

  if (!path) {
    throw new Error('Path is required')
  }

  const { data, error, isLoading, isValidating, mutate } = useSWR<Data>(
    insertParamsIntoUrl(path, params, query),
    boundFetcher
  )

  if (error && error instanceof ApiError) {
    console.log('fetch error', error)

    if ([401, 403].indexOf((error as ApiError<unknown>).status) > -1) {
      router.push(signinUrl)
    }
  }

  return {
    data,
    error,
    hasFetched: !isLoading,
    failed: error !== undefined,
    isLoading,
    isValidating,
    mutate,
  }
}

export type WithParams = {
  params?: UrlParams | null | undefined
  query?: UrlQuery | null | undefined
}

export type WithParamsFun = () => WithParams

export const useParameterizedFetchApi = <Data = unknown>(
  path: string,
  withParams: WithParamsFun,
  options: FetchApiOptions
): ApiHooksResult => {
  const { signinUrl } = options

  const router = useRouter()
  const boundFetcher = useBoundFetcher(options, router)

  const { data, error, isLoading, isValidating, mutate } = useSWR<Data>(() => {
    const { params, query } = withParams()
    return insertParamsIntoUrl(path, params, query)
  }, boundFetcher)

  if (error && error instanceof ApiError) {
    console.log('fetch error', error)

    if ([401, 403].indexOf((error as ApiError<unknown>).status) > -1) {
      router.push(signinUrl)
    }
  }

  return {
    data,
    error,
    hasFetched: !isLoading,
    failed: error !== undefined,
    isLoading,
    isValidating,
    mutate,
  }
}

type UseMutationApiProps = {
  method: string
  path: string
  params?: UrlParams | null | undefined
  query?: UrlQuery | null | undefined
  body?: BodyInit | Record<string, unknown> | null | undefined
  options: FetchApiOptions
}

export type UseMutationApiCallbacks = {
  onSuccess?: () => void
  onError?: () => void
}

const withMutationApiFetcher =
  ({ method, options, baseApiUrl }) =>
  (urlPath, { arg }) => {
    // console.log('~~~~~~~~~~~~~~~~~~> calling useSwrMutation custom fetch', urlPath, arg)
    const isJson = !!arg && isPlainObject(arg)

    return fetcher(
      baseApiUrl(urlPath),
      fetchOptions({
        method,
        options,
        isJson,
        body: isJson ? (JSON.stringify(arg) as string) : arg,
      })
    )
  }

const useMutationApi = (
  mutationParams: UseMutationApiProps,
  callbacks?: UseMutationApiCallbacks
) => {
  // const { method, path, params, query, body, options } = mutationParams
  const { method, path, params, query, options } = mutationParams
  const { baseApiUrl } = options
  const router = useRouter()
  // const boundFetcher = useBoundFetcher(options, router)

  // console.log('useMutationApi', mutationParams)
  // console.log('---> router ??? ', getCurrentRouterLocale(router))
  if (!method) {
    throw new Error('Method is required')
  }

  if (!path) {
    throw new Error('Path is required')
  }

  const swrOptions: SWRMutationConfiguration<unknown, Error, string, string> =
    {}

  if (callbacks?.onSuccess) {
    swrOptions.onSuccess = callbacks.onSuccess
  }
  if (callbacks?.onError) {
    swrOptions.onError = callbacks.onError
  }

  // console.log('----> calling useSwrMutation path', insertParamsIntoUrl(path, params, query))
  // console.log('----> calling useSwrMutation options', swrOptions)
  return useSWRMutation(
    insertParamsIntoUrl(path, params, query),
    withMutationApiFetcher({
      method,
      options: {
        ...options,
        country: getCurrentRouterLocale(router),
      },
      baseApiUrl,
    }),
    // (urlPath, { arg }) => {
    //   console.log('----> calling useSwrMutation custom fetch', urlPath, arg)
    //   const isJson = !!arg && isPlainObject(arg)

    //   return fetcher(
    //     baseApiUrl(urlPath),
    //     fetchOptions({
    //       method,
    //       options,
    //       isJson,
    //       body: isJson ? (JSON.stringify(arg) as string) : arg,
    //       router,
    //     })
    //   )
    // },
    swrOptions
  )
}

type UsePostApiProps = Omit<UseMutationApiProps, 'method'>
type UseUpdateApiProps = Omit<UseMutationApiProps, 'method'>
type UsePatchApiProps = Omit<UseMutationApiProps, 'method'>
type UseDeleteApiProps = Omit<UseMutationApiProps, 'method' | 'body'>

export const usePostApi = (
  props: UsePostApiProps,
  callbacks?: UseMutationApiCallbacks
) => useMutationApi({ method: 'post', ...props }, callbacks)

export const useUpdateApi = (
  props: UseUpdateApiProps,
  callbacks?: UseMutationApiCallbacks
) => useMutationApi({ method: 'put', ...props }, callbacks)

export const usePatchApi = (
  props: UsePatchApiProps,
  callbacks?: UseMutationApiCallbacks
) => useMutationApi({ method: 'patch', ...props }, callbacks)

export const useDeleteApi = (
  props: UseDeleteApiProps,
  callbacks?: UseMutationApiCallbacks
) => useMutationApi({ method: 'delete', ...props }, callbacks)
