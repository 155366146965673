import { insertParamsIntoUrl } from '@slc/utils';
import type { UrlParams } from '@slc/utils';

import { PATH_AUTH } from '../routes/paths'

export const baseUrl = process.env.NEXT_PUBLIC_DASHBOARD_URL

export const getDashboardLinkHref = (path: string, params?: UrlParams) =>
  insertParamsIntoUrl(`${baseUrl}${path}`, params)

export const getSigninLinkHref = getDashboardLinkHref(PATH_AUTH.login)