import { API_PATHS, fetchApi } from '@/utils/api'
import { Currency, LangItem, PaymentType, SiteWithLangs, Tax } from '@slc/types'
import { PageLoader } from '@slc/ui'
import { ReactNode, createContext, useState, useEffect, useContext } from 'react'

export type CountryContext = {
  locale?: string
  country: SiteWithLangs
  isLoading: boolean
  error?: Error
}

const initialCountry: SiteWithLangs = {
  site: '',
  currency: {},
  tax: {},
  stripe: {},
  paymentTypes: [],
  langs: [],
}
const initialState: CountryContext = {
  country: initialCountry,
  isLoading: false,
  error: undefined,
}

const CountryContext = createContext(initialState)

type CountryProviderProps = {
  locale?: string
  children: ReactNode
}

export const useLangs = (): LangItem[] => useContext(CountryContext).country?.langs ?? []
export const usePaymentTypes = (): PaymentType[] =>
  useContext(CountryContext).country?.paymentTypes ?? []
export const useCurrency = (): Currency | null =>
  useContext(CountryContext).country?.currency ?? null
export const useCurrencyCode = (): string =>
  useContext(CountryContext).country?.currency?.code ?? ''
export const useTax = (): Tax | null => useContext(CountryContext).country?.tax ?? null

const fetchInfos = async (locale: string): Promise<SiteWithLangs> =>
  await fetchApi<SiteWithLangs>({ path: API_PATHS.site.siteInfos, options: { country: locale } })

function CountryProvider({
  children,
  locale = process.env.NEXT_PUBLIC_DEFAULT_SITE,
}: CountryProviderProps) {
  const [countryInfos, setCountryInfos] = useState<CountryContext>(initialState)

  useEffect(() => {
    if (countryInfos.isLoading) {
      return
    }

    if (!countryInfos.locale || countryInfos.locale !== locale) {
      setCountryInfos({
        ...countryInfos,
        locale,
        isLoading: true,
      })
    }
  }, [locale, countryInfos])

  useEffect(() => {
    if (!countryInfos.isLoading) {
      return
    }

    const fetch = async () => {
      console.log('----> country fetch infos', locale)

      const country = await fetchInfos(locale as string)
      console.log('--->', country)
      setCountryInfos({
        locale,
        country,
        isLoading: false,
      })
    }

    fetch()
  }, [locale, countryInfos])

  return countryInfos.isLoading ? (
    <PageLoader />
  ) : (
    <CountryContext.Provider value={{ ...countryInfos }}>{children}</CountryContext.Provider>
  )
}

export { CountryProvider, CountryContext }
