export const ADMIN_ROLE = 'admin'
export const ACCOUNTING_ROLE = 'accounting'
export const MANAGER_ROLE = 'manager'

export const CUSTOMER_ROLE = 'customer'
export const PREMIUM_ROLE = 'premium'
export const RESELLER_ROLE = 'reseller'

export const TRANSLATOR_ROLE = 'translator'
export const PRODUCER_ROLE = 'producer'
