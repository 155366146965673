import { defaultSite } from '@slc/utils'
import { useRouter, type NextRouter } from 'next/router'

export const getCurrentRouterLocale = (router: NextRouter) =>
  router.locale || router.defaultLocale || defaultSite()

export const useCurrentRouterLocale = () => {
  const router = useRouter()

  return getCurrentRouterLocale(router)
}

export const useCurrentCountryCode = useCurrentRouterLocale
