// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`
}

const ROOTS_AUTH = '/api/auth'
const ROOTS_DASHBOARD = '/'
const ROOTS_AUTH_PAGE = '/auth'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  logout: path(ROOTS_AUTH, '/logout'),
  required: path(ROOTS_AUTH_PAGE, '/required'),
}

export const PATH_PAGE = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: '/app',
    order: '/order',
    credits: '/credits',
    customer: '/customer',
    // ecommerce: '/ecommerce',
    // analytics: '/analytics',
    // banking: '/banking',
    // booking: '/booking',
  },
  customer: {
    root: '/customer',
    new: '/customer/new',
    list: '/customer/list',
    view: (cid: string) => `/customer/${cid}`,
    edit: (cid: string) => `/customer/${cid}/edit`,
  },
  user: {
    root: '/user',
    new: '/user/new',
    list: '/user/list',
    cards: '/user/cards',
    profile: '/user/profile',
    account: '/user/account',
    edit: (name: string) => `/user/${name}/edit`,
  },
  order: {
    root: '/order',
    messages: '/order/messages',
    // messageStatus: '/order/messages/:status',
    list: '/order/list',
    new: '/order/new',
    view: (id: string) => `/order/${id}`,
    // edit: (id: string) => `/order/${id}/edit`,
  },
  orderMessage: {
    root: '/order/message',
    list: '/order/message/list',

    view: (id: string) => `/order/message/${id}`,
  },
  message: {
    root: '/message',
    list: '/message/list',
    view: (id: string) => `/message/${id}`,
  },
  production: {
    root: '/production',
    incoming: '/production/message/incoming',
    waiting: '/production/message/waiting',
    production: '/production/message/production',
    cancelled: '/production/message/cancelled',
    delivered: '/production/message/delivered',
    voice: {
      root: '/production/voice',
      messages: '/production/voice/messages',
    },
    translation: {
      root: '/production/translation',
      messages: '/production/translation/messages',
    },
  },
  producer: {
    root: '/producer',
    list: '/producer/list',
    accounting: {
      root: '/producer/accounting',
      costs: '/producer/accounting/costs',
      invoice: '/producer/accounting/invoice',
    },
  },
  producerDashboard: {
    root: '/dashboard/producer',
    voice: {
      messages: '/dashboard/producer/voice/messages',
    },
    translation: {
      messages: '/dashboard/producer/translation/messages',
    },
    user: {
      root: '/dashboard/producer/user',
      availability: '/dashboard/producer/user/availability',
      information: '/dashboard/producer/user/informations',
    },
    accounting: {
      root: '/dashboard/producer/accounting',
      cost: '/dashboard/producer/accounting/cost',
    },
  },
  invoice: {
    root: '/invoice',
    list: '/invoice/list',
    new: '/invoice/new',
  },
  refund: {
    root: '/refund',
    list: '/refund/list',
  },
  quote: {
    root: '/quote',
    list: '/quote/list',
  },
  credits: {
    root: '/credit',
    list: '/credit/list',
  },
  statement: {
    root: '/statement',
    list: '/statement/list',
  },
  promocode: {
    root: '/promocode',
    list: '/promocode/list',
    new: '/promocode/new',
    view: (id: string) => `/promocode/${id}`,
    edit: (id: string) => `/promocode/${id}/edit`,
  },
}
