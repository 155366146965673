import type { UrlParams, UrlQuery } from '@slc/utils'
import { insertParamsIntoUrl } from '@slc/utils'

export const url = (baseUrl: string, path: string) => {
  if (!baseUrl) {
    throw new Error('Missing baseUrl')
  }

  if (!path) {
    return baseUrl
  }

  return [baseUrl, path].join(path[0] === '/' ? '' : `/${path}`)
}

const prependApi = (path: string) =>
  path.substring(0, 4) === '/api' ? path : url('/api', path)

export const buildUrl = (
  baseUrl: string,
  path: string,
  params?: UrlParams | null | undefined,
  query?: UrlQuery | null | undefined
) => {
  if (!baseUrl) {
    throw new Error('Missing baseUrl')
  }

  return insertParamsIntoUrl(url(baseUrl, prependApi(path)), params, query)
}

export const apiUrl = (
  path: string,
  params: UrlParams | null | undefined,
  query: UrlQuery | null | undefined
) => buildUrl(process.env.NEXT_PUBLIC_API_URL as string, path, params, query)

export const appUrl = (
  path: string,
  params?: UrlParams | null | undefined,
  query?: UrlQuery | null | undefined
) => buildUrl(process.env.NEXT_PUBLIC_APP_URL as string, path, params, query)

export const authUrl = (
  path: string,
  params?: UrlParams | null | undefined,
  query?: UrlQuery | null | undefined
) => buildUrl(process.env.NEXT_PUBLIC_AUTH_URL as string, path, params, query)

export const customerUrl = (
  path: string,
  params?: UrlParams | null | undefined,
  query?: UrlQuery | null | undefined
) =>
  buildUrl(process.env.NEXT_PUBLIC_CUSTOMER_URL as string, path, params, query)

export const dashboardUrl = (
  path: string,
  params?: UrlParams | null | undefined,
  query?: UrlQuery | null | undefined
) =>
  buildUrl(process.env.NEXT_PUBLIC_DASHBOARD_URL as string, path, params, query)

export const studioUrl = (
  path: string,
  params?: UrlParams | null | undefined,
  query?: UrlQuery | null | undefined
) => buildUrl(process.env.NEXT_PUBLIC_STUDIO_URL as string, path, params, query)

export const auth0Url = (path: string) =>
  url(`https://${process.env.AUTH0_DOMAIN}`, path)

export const auth0ApiUrl = (path: string) =>
  url(`https://${process.env.AUTH0_DOMAIN}/api/v2`, path)
