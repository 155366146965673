import $dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

$dayjs.extend(utc)

export const dayjs = $dayjs

export const toDate = (date: Date | string | undefined): Date | undefined => {
  if (!date) {
    return undefined
  }

  return dayjs(date).toDate()
}
