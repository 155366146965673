import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'

const Root = styled('div')(() => ({
  display: 'flex',
  height: '100vh',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
}))

export const PageLoader = () => (
  <Root>
    <CircularProgress />
  </Root>
)
