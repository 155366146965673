import { isDate } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import { formatISODate } from './dateFormat'

const paramPattern = /:([^/]+)/g

export type UrlParams = Record<string, unknown>
export type UrlQueryValue =
  | string
  | number
  | boolean
  | string[]
  | number[]
  | Date
export type UrlQuery = Record<string, UrlQueryValue>

export const insertQueryIntoUrl = (
  href: string,
  query?: UrlQuery | null | undefined
) => {
  if (!query || isEmpty(query)) {
    return href
  }

  const queryParams: string[] = []

  Object.keys(query).forEach((name) => {
    let value = query[name]
    if (isDate(value)) {
      value = formatISODate(value)
    }
    queryParams.push(`${name}=${encodeURIComponent(value)}`)
  })

  const queryStr: string = queryParams.join('&')

  return href.indexOf('?') === -1
    ? `${href}?${queryStr}`
    : `${href}&${queryStr}`
}

export const insertParamsIntoUrl = (
  href: string,
  params?: UrlParams | null | undefined,
  query?: UrlQuery | null | undefined
) => {
  if (!params || isEmpty(params)) {
    return insertQueryIntoUrl(href, query)
  }

  const tokens = href.match(paramPattern)

  if (!tokens) {
    return insertQueryIntoUrl(href, query)
  }

  tokens.forEach((token) => {
    const value = params[token.substring(1)] as string

    if (value) {
      href = href.replace(token, encodeURIComponent(value))
    }
  })

  return insertQueryIntoUrl(href, query)
}

export const pathHrefToRoute = (href = '') => {
  const tokens = href.match(paramPattern)

  if (!tokens) {
    return href
  }

  tokens.forEach((token) => {
    const name = token.substring(1)

    if (name) {
      href = href.replace(token, `[${name}]`)
    }
  })

  return href
}
