import { ReactNode, createContext, useContext, useMemo } from 'react'
import { fetchApi, type FetchApiProps } from '@/utils/api'
import { useRouter } from 'next/router'
import { defaultLang } from 'config'
import { ApiResponseOk } from '@slc/types'

export type ApiCallFetchFunction<T = unknown> = ({
  path,
  params,
  query,
}: Omit<FetchApiProps, 'options'>) => Promise<T | ApiResponseOk>

// type BoundFetch<T = unknown> = <T>(locale: string) => (props: Omit<FetchApiProps, 'options'>) => Promise<T | ApiResponseOk>
// type BoundFetch<T = unknown> = (locale: string) => ApiCallFetchFunction<T>

const bindFetch =
  <T,>(locale: string) =>
  ({ path, params, query }: Omit<FetchApiProps, 'options'>) =>
    fetchApi<T>({
      path,
      params,
      query,
      options: {
        country: locale,
      },
    })

export type ApiCallContextProps = {
  fetch: ApiCallFetchFunction
}

const initialState: ApiCallContextProps = {
  fetch: bindFetch(defaultLang.value),
}

const ApiCallContext = createContext(initialState)

export const useApiFetchable = () => useContext(ApiCallContext).fetch
export const useApiFetch = (props: Omit<FetchApiProps, 'options'>) =>
  useContext(ApiCallContext).fetch(props)

type ApiCallProviderProps = {
  children: ReactNode
}

function ApiCallProvider({ children }: ApiCallProviderProps) {
  const { locale, defaultLocale } = useRouter()

  const memoizedValue = useMemo(
    () => ({
      fetch: ({ path, params, query }: Omit<FetchApiProps, 'options'>) =>
        fetchApi({
          path,
          params,
          query,
          options: {
            country: locale || defaultLocale,
          },
        }),
    }),
    [locale, defaultLocale]
  )

  return <ApiCallContext.Provider value={memoizedValue}>{children}</ApiCallContext.Provider>
}

export { ApiCallProvider, ApiCallContext }
