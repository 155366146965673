// icons
import { Icon, IconifyIcon } from '@iconify/react'
// @mui
import { Box, BoxProps, SxProps } from '@mui/material'
// @bootstraop icons
import type { Icon as BTIconType } from 'react-bootstrap-icons'
// ----------------------------------------------------------------------

interface Props extends BoxProps {
  // sx?: SxProps;
  icon: IconifyIcon | string
}

export default function Iconify({ icon, sx, ...other }: Props) {
  const props: Props = { icon }

  if (sx) {
    props.sx = sx
  }

  return <Box component={Icon} {...props} {...other} />
}

interface BTProps extends BoxProps {
  sx?: SxProps
  Icon: BTIconType
}

export function BTIcon({ Icon, sx, ...other }: BTProps) {
  const props = {
    sx: sx || {
      width: '1rem',
      height: '1rem',
      padding: '.3rem',
    },
  }

  return (
    <Box component="span" {...props} {...other}>
      <Icon />
    </Box>
  )
}
