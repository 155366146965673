import { PATH_AUTH } from '@/routes/paths'
import { UserProfile, UserRoles } from '@/types/user'
import { getUserRoles } from '@/utils/auth0'
import { useUser } from '@auth0/nextjs-auth0'
import { PRODUCER_ROLE } from '@slc/constants/role'
import { Site, UserType, Uuid } from '@slc/types'
import { PageLoader } from '@slc/ui'
import type { Router } from 'next/router'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

export type SessionType = {
  type: string
  [key: string]: string
}

export type Session = {
  roles?: UserRoles
  // site?: Site
  allowedSites?: Site[]
  uid: Uuid | null
  type: SessionType | null
}
export type SessionContext = {
  session?: Session
  error?: Error
  isLoading: boolean
  // checkSession: () => Promise<void>;
}

const initialSession = {
  roles: undefined,
  site: undefined,
  uid: null,
  type: null,
}

const initialState: SessionContext = {
  session: initialSession,
  isLoading: true,
  error: undefined,
}

type SessionProviderProps = {
  children: ReactNode
  authRequired?: boolean
  router: Router
}
type AuthSessionProviderProps = Omit<SessionProviderProps, 'authRequired'>

type AnonymousSessionProviderProps = {
  children: ReactNode
}

const SessionContext = createContext(initialState)

export const useSession = () => useContext<SessionContext>(SessionContext)

export function AuthSessionProvider({ children, router }: AuthSessionProviderProps) {
  const { user, isLoading } = useUser()
  const [session, setSession] = useState<SessionContext>({
    session: {
      ...initialSession,
    },
    isLoading: false,
    error: undefined,
  })

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (!user) {
      console.log('-- - --> 401', PATH_AUTH.required)

      console.log(`redirect to ${PATH_AUTH.required}`)
      if (window) {
        window.location.href = `${window.location.origin}/${PATH_AUTH.required}`
      } else {
        router.push(PATH_AUTH.required)
      }
      return
    }

    const roles = getUserRoles(user)
    console.log('.roles', roles)

    setSession({
      isLoading: false,
      session: buildSession(user as UserProfile),
    })
  }, [isLoading, user])

  console.log('session', session)

  return session.isLoading ? (
    <PageLoader />
  ) : (
    <SessionContext.Provider value={session}>{children}</SessionContext.Provider>
  )
}

export function AnonymousSessionProvider({ children }: AnonymousSessionProviderProps) {
  console.log('[anon session]')
  return (
    <SessionContext.Provider
      value={{
        session: {
          ...initialSession,
        },
        isLoading: true,
        error: undefined,
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}

export default function SessionProvider({
  children,
  router,
  authRequired = true,
}: SessionProviderProps) {
  return authRequired ? (
    <AuthSessionProvider router={router}>{children}</AuthSessionProvider>
  ) : (
    <AnonymousSessionProvider>{children}</AnonymousSessionProvider>
  )
}

const buildSession = (user: UserProfile | undefined): Session => {
  if (!user) {
    return initialSession
  }

  const { uid, type, site } = user

  return {
    roles: getUserRoles(user),
    type: buildSessionType(type),
    allowedSites: site,
    uid,
  }
}

export type SessionProducer = SessionType & {
  pdid: Uuid
}

const buildSessionType = (userType: UserType): SessionType | null => {
  if (!userType) {
    return null
  }

  const sessionType: SessionType = {
    type: userType.role,
    [userType.prop as string]: userType.id,
  }

  return sessionType
}

export const isProducer = (session?: Session) => {
  if (!session) {
    return false
  }

  console.log('isProducer', session, session.type)
  return session.type?.type === PRODUCER_ROLE
}
